<template>
<el-form  label-position="top" size="large">
    <el-form-item label="" size="default">
        <el-switch class="mr-2" v-model="options.autoDownload.enabled" :active-value="true" :inactive-value="false">
        </el-switch>
        Auto download media from new posts
    </el-form-item>
    
    <InfoLabel label="Download folder path">
        <p> This is the path where files downloaded by this tool will be saved. </p>
        <p> It should be relative to your downloads folder path. </p>
        <p> It can have variables listed below to help you organize your downloaded files.</p>
    </InfoLabel>
    
    <el-form-item label="">
        <el-input v-model="options.folderStructure" placeholder="" clearable></el-input>
        <span class="mr-2">Available variables:  </span>
        <el-tag class="mr-1" type="primary" size="default"  effect="light">{website}</el-tag>
        <el-tag class="mr-1" type="primary" size="default"  effect="light">{mediaSource}</el-tag>
        <el-tag class="mr-1" type="primary" size="default"  effect="light">{mediaType}</el-tag>
        <el-tag class="mr-1" type="primary" size="default"  effect="light">{fileName}</el-tag>                        
    </el-form-item>

    <InfoLabel label="Short video length (in seconds)">
        <p> This is the duration of videos which can be considered short. Used in 'Hide short videos' feature </p>
    </InfoLabel>
    
    <el-form-item label="">
        <el-input v-model.number="options.shortVideoLength" placeholder="" clearable></el-input>
    </el-form-item>
    <div v-if="store.user.isPremium" class="mb-4">
        <div class="mb-2">
            <label>
                Subscription
            </label>
        </div>
        <div v-if="store.user.subscription.isActive">
            Renews on: {{ formatDate(store.user.subscription.endsAt) || '--' }}
            <el-button :loading="loading.cancel" type="text" :icon="ElIconClose" @click="cancelSubscription()">Cancel subscription</el-button>
        </div>
        <div v-else>
            Ends on: {{ formatDate(store.user.subscription.endsAt) || '--' }}
        </div>
    </div>
    <el-button type="primary" @click="saveOptions" :icon="ElIconCheck">Save</el-button>
</el-form>
</template>

<script>
const {useExtension} = useHelpers()
const {formatDate} = useFilters()
let store = {}
export default {
    beforeCreate() {
        store = useStore()
    },
    provide () {
        return {
            jobs: () => this.jobs
        }
    }, 
	data () {
		return {
            localStorage,
            store,
            options: {
				hideLocked: false,
                mediaType: 'all',
                shortVideoLength: 30,
                autoDownload: {
                    enabled: false
                },
                folderStructure: '{website}/{mediaType}/{fileName}'
			},
        }
    },
    async created () {
        let [storageReq] = await Promise.all([
            useExtension('chrome.storage.local.get', [['options', 'data']])
        ])
        Object.assign(this.options, storageReq.result?.options || {})
    },
    methods: {
        formatDate,
        async saveOptions () {
            await useExtension('chrome.storage.local.set', [{options: this.options}])
            return ElNotification({
                type: 'success',
                title: 'Success',
                message: 'Settings saved'
            })
        },
    }
}
</script>