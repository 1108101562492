<template>
<div>
    <downloads></downloads>
    <el-card v-loading="loading.initial" shadow="never" :body-style="{ padding: '10px 20px' }" class="mb-4 mx-auto"  style="min-height: 100px">
        <el-tabs v-if="!loading.initial" v-model="activeTab" tab-position="top">
            <el-tab-pane label="Find media" name="download">
                <download-form ref="form" v-loading="loading.media" @download="submitForm" :extensionInfo="extensionInfo" :me="me"></download-form>
            </el-tab-pane>
            <el-tab-pane label="Results" name="results">
                <div class="mb-4 flex justify-between items-center">
                    <div class="flex gap-2">
                        <el-checkbox size="large" border v-model="options.hideShort" :label="true">Hide short videos</el-checkbox>
                        <el-radio-group size="large" v-model="options.mediaType">
                            <el-radio-button label="video">Video</el-radio-button>
                            <el-radio-button label="photo">Photo</el-radio-button>
                            <el-radio-button label="all">All</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <el-button :loading="flags.downloadingAll" size="large" :icon="ElIconDownload" @click="downloadAll()" title="Download all loaded videos on this page">Download All &mdash; {{filteredMedia.length}}</el-button>
                        <el-button :loading="flags.downloadingAll" size="large" :icon="ElIconDownload" @click="bulkDownload()" title="Download all videos matching these filters">Bulk Download</el-button>
                    </div>
                </div>
                <div v-if="hiddenCount > 0" class="text-sm mb-4 text-gray-500">
                    {{hiddenCount}} item{{hiddenCount > 1 ? 's are' : ' is'}} hidden
                </div>        
                <div class="mb-4">
                    <el-alert v-if="!localStorage.warningRead" title="Warning" type="warning" effect="light" show-icon closable @close="localStorage.warningRead = true">
                        By downloading the media files, you agree that you will only keep them for yourself and will not share them with anyone else.
                    </el-alert>
                </div>
                <div class="flex flex-wrap mb-4 gap-x-4 gap-y-8" v-if="filteredMedia.length > 0">
                    <el-card class="flex" style="width: 400px; border: none;" shadow="never" :body-style="{padding: '0', flexGrow: 1, display: 'flex'}"  v-for="media in filteredMedia" :key="media.id">
                        <media :media="media" :source="type" :job="jobs.find(job => job.id == media.id)"></media>
                    </el-card>
                </div>
                <div class="flex flex-col justify-center items-center gap-2 mb-1">
                    <div v-if="loading.media" class="flex gap-5">
                        Loading media...
                        <ElIconLoading width="20"></ElIconLoading>
                    </div>
                    <div v-else-if="media.length == 0">No downloadable media found</div>
                    <div v-else-if="!cursor" class="text-center">No more items to load</div>
                    <template v-else-if="!store.user.isPremium">
                        <p> Subscribe to premium plan to access more downloads - $10 / month  </p>
                        <google-signin v-if="!store.user._id" :width="200"></google-signin>
                        <div v-else style="width:200px">
                            <paypal-button></paypal-button>
                        </div>
                    </template>
                    <div v-else>
                        <el-button type="text" @click="handleScroll()" :icon="ElIconArrowDown">Load more</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Settings" name="settings">
                <settings></settings>
            </el-tab-pane>
        </el-tabs>
    </el-card>
    <el-dialog
        title="Download links"
        v-model="downloadLinks.show"
        width="40%"
        @close="downloadLinks.show = false">
        <el-alert type="info" :closable="false" show-icon title="You can only download these links from your current IP address"></el-alert>
        <div class="mb-4"></div>
        <div style="white-space: nowrap; word-break: none; max-height: 500px; overflow: auto">
            <div v-for="link in downloadLinks.links" :key="link"> {{link}} </div>
        </div>
    </el-dialog>
</div>
</template>

<script>
const {
    useExtension, 
    getExtensionID, 
    generateFilePath, 
    parseUrl, 
    waitForExtensionConnection
} = useHelpers()
const onlyfans = useOnlyfans()
const fansly = useFansly()
const redgifs = useRedgifs()
const {formatDate} = useFilters()

let store = {}
let messageHandler
export default {
    beforeCreate() {
        store = useStore()
    },
    provide () {
        return {
            jobs: () => this.jobs
        }
    }, 
	data () {
		return {
            flags: {
                downloadingAll: false
            },
            localStorage,
            store,
			allItemsLoaded: false,
            showDemoVideo: false,
            downloadLinks: {
                show: false,
                links: []
            },
            drmJobs: [],
            downloadJobs: [],
            options: {
				hideLocked: false,
                mediaType: 'all'
			},
			loading: {
				initial: true,
				media: false,
                cancel: false
			},
            urlInfo: {type: '', websiteName: '', chatId: '', username: '', postId: ''},
			activeTab: 'download',
			extensionInfo: { version: '' },
			me: { id: '', name: '', email: '', loading: true},
			form: {},
			items: [],
			media: [],
			cursor: undefined
		}
	},
	async beforeMount () {
        this.showDemoVideo = false
		let result = await waitForExtensionConnection()
        this.loading.initial = false
        Object.assign(this.extensionInfo, result)
        this.init()
        this.connectToPort()
	},
	destroyed () {
    	window.removeEventListener('scroll', this.handleScroll)
        extensionPort?.onMessage.removeListener(messageHandler)
  	},
	methods: {
        formatDate,
        async cancelSubscription () {
            this.loading.cancel = true
            let response = await useApi('/user/me/subscription/cancel', {method: 'post'})
            if (response.success) {
                this.store.user.subscription.isActive = false
                ElNotification({
                    type: 'success',
                    title: 'Subscription canceled',
                    message: 'Your subscription has been canceled successfully.'
                })
            }
            this.loading.cancel = false
        },
        connectToPort () {
            messageHandler = message => {
                if (message.type == 'drm_downloader_update') {
                    this.drmJobs = message.data.jobs
                } else if (message.type == 'downloader_update') {
                    this.downloadJobs = message.data.jobs
                }
            }
            window.extensionPort = chrome.runtime.connect(getExtensionID())
            extensionPort.onMessage.addListener(messageHandler)
        },
        getLinks () {
            let links = this.media.map(item => item.full || item.files.full.url)
            this.downloadLinks.links = links
            this.downloadLinks.show = true
        },
		async init () {
            let [storageReq, drmStateReq, dlStateReq] = await Promise.all([
                useExtension('chrome.storage.local.get', [['options', 'data']]),
                useExtension('drmDownloadPool.getState', [], 'offscreen'),
                useExtension('downloadPool.getState', [], 'sandbox'),
            ])
            this.drmJobs = drmStateReq.result?.jobs ?? []
            this.downloadJobs = dlStateReq.result?.jobs ?? []
            Object.assign(this.options, storageReq.result?.options || {})
            this.loading.initial = false
            
			if (this.extensionInfo.version) {
				this.me = await onlyfans.getMe()
                this.me.loading = false
			}
			window.addEventListener('scroll', this.handleScroll)
            this.connectToPort()
		},
		async bulkDownload () {
            if (!this.store.user.isPremium) {
                return ElNotification({
                    type: 'error',
                    title: 'Premium required',
                    message: 'You need to be a premium user to use this feature, scroll down to subscribe to premium plan.'
                })
            }
            this.flags.downloadingAll = true
            useExtension('taskManager.addTask', ['BulkDownload', this.form], 'sandbox')
            ElNotification({
                type: 'success',
                title: 'Task started',
                message: 'Bulk downloader task has been started!'
            })
            setTimeout(() => this.flags.downloadingAll = false, 10e3)
        },
		async downloadAll () {
            this.flags.downloadingAll = true
            for (let item of this.filteredMedia) {
                if (item.files?.drm) {
                    // let {error} = await useExtension('downloadDRM', [item], 'offscreen')
                    // if (error) ElNotification({title: 'DRM Error', message: error, type: 'error'})
                } else {
                    let filePath = await generateFilePath(item)
                    item.filePath = filePath
                    await useExtension('downloadPool.addJob', [item, item.id], 'sandbox')
                }
			}
            setTimeout(() => this.flags.downloadingAll = false, 10e3)
		},
		async handleScroll () {
			if (this.activeTab != 'results') return
			if (!this.store.user.isPremium) return
			const bottomOfWindow = document.documentElement.scrollHeight - window.innerHeight - 600;
			if (window.scrollY > bottomOfWindow && !this.loading.media && this.cursor) {
				let media = await this.fetchMedia()
				this.media = this.media.concat(media)
			}
		},


		validateForm (form) {
			return new Promise(resolve => form.validate(resolve))
		},
		async fetchMedia () {
			let media = []
			let {form} = this
            let {type, websiteName, chatId, username, postId} = this.urlInfo
			this.loading.media = true
            console.log({form, urlInfo: this.urlInfo})
            if (websiteName == 'onlyfans.com') {
                if (form.source == 'all') {
                    let offset = typeof this.cursor == 'number' ? this.cursor : 0
                    let {items} = await onlyfans.getMediaFromFollowing({offset})
                    console.log('getMediaFromFollowing', items)
                    media = items
                    this.cursor = offset + 10
                } else if (type == 'post') {
                    let items = await onlyfans.getMediaFromPost(form.url)
                    media = items
                    this.cursor = ''
                } else if (type == 'user') {
                    let {items, cursor} = await onlyfans.getMediaFromUser({url: form.url, cursor: this.cursor})
                    this.cursor = cursor
                    media = items
                } else if (type == 'chat') {
                    let {items, cursor} = await onlyfans.getMediaFromMessages({url: form.url, cursor: this.cursor})
                    this.cursor = cursor
                    media = items
                }
            } else if (websiteName.match(/fansly\.com|fans\.ly/)) {
                if (form.source == 'all') {
                    let offset = typeof this.cursor == 'number' ? this.cursor : 0
                    let {items} = await fansly.getPosts({offset})
                    for (let post of items) {
                        if (post.media) {
                            media = media.concat(post.media)
                            media.forEach(media => media.postId = post.id)
                        }
                    }
                    this.cursor = offset + 10
                } else if (type == 'post') {
                    let items = await fansly.getSinglePostDownloadUrls(postId)
                    media = items
                    this.cursor = ''
                } else if (type == 'user') {
                    let {items, cursor} = await fansly.getCreatorDownloadUrls({username, cursor: this.cursor})
                    this.cursor = cursor
                    media = media.concat(items)
                } else if (type == 'chat') {
                    let {items, cursor} = await fansly.getMessagesDownloadUrls({chatId, cursor: this.cursor})
                    this.cursor = cursor
                    media = media.concat(items)
                }
            } else if (websiteName == 'redgifs.com') {
                if (form.source == 'all') {
                    let offset = typeof this.cursor == 'number' ? this.cursor : 0
                    let {items} = await fansly.getPosts({offset})
                    for (let post of items) {
                        if (post.media) {
                            media = media.concat(post.media)
                            media.forEach(media => media.postId = post.id)
                        }
                    }
                    this.cursor = offset + 10
                } else if (type == 'post') {
                    let items = await redgifs.getPostMedia(postId)
                    media = items
                    this.cursor = ''
                } else if (type == 'user') {
                    let page = typeof this.cursor == 'number' ? this.cursor : 1
                    let {items, cursor} = await redgifs.getUserMedia(username, page)
                    this.cursor = cursor
                    media = media.concat(items)
                    console.log(media)
                } else if (type == 'chat') {
                    let {items, cursor} = await redgifs.getMessagesDownloadUrls({chatId, cursor: this.cursor})
                    this.cursor = cursor
                    media = media.concat(items)
                }
            }
            
			this.activeTab = 'results'
			setTimeout(() => {
				this.loading.media = false
				this.handleScroll()
			}, 1000)
			return media
		},
		async submitForm (form) {
			let isValid = await this.validateForm(this.$refs.form.$refs.downloadForm)
			if (!isValid) return
			this.cursor = ''
			this.form = form
            if (form.source != 'all') {
                this.urlInfo = parseUrl(this.form.url)
                if (!this.urlInfo) {
                    return ElNotification({
                        type: 'error',
                        title: 'URL not supported',
                        message: 'This URL is not recognized as a post, profile or chat URL'
                    })
                }
            } else {
                this.urlInfo.websiteName = this.$route.fullPath.includes('fansly') ? 'fansly.com' : 'onlyfans.com'
            }
            if (!getExtensionID() || !this.extensionInfo.version) {
                return ElNotification({
                    type: 'error',
                    title: 'Unable to connect to extension',
                    message: 'Make sure that the chrome extension is installed, enabled and upto date.'
                })
            }
            this.media = await this.fetchMedia(this.urlInfo)
		}
	},
    computed: {
        jobs () {
            return this.downloadJobs.concat(this.drmJobs)
        },
        filteredMedia () {
            return this.media.filter(media => {
                if (!media) return 
                let {hideLocked, hideShort, shortVideoLength, mediaType} = this.options
                if (hideLocked && !media.canView) return false
                if (hideShort) {
                    if (media.type == 'video' && media.duration < shortVideoLength) return false
                }
                if (mediaType != 'all' && media.type != mediaType) return false
                return true
            })
        },
        hiddenCount () {
            return this.media.length - this.filteredMedia.length
        }
    }
}
</script>

<style>
	.el-popover {
		word-break: keep-all!important;
	}
	.el-checkbox {
		margin-right: 0;
	}
</style>