<template>
	<div class="flex items-center gap-2 mb-2">
		<label class="inline-block">{{label}}</label>
		<el-popover placement="bottom" width="500" :content="content" :title="title">
			<template #reference>
				<el-icon><ElIconWarning class="text-gray-500"></ElIconWarning></el-icon>
			</template>
			<slot></slot>
		</el-popover>
	</div>
</template>

<script>
export default {
	props: ['label', 'content', 'title']
}
</script>