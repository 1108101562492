import _ from 'lodash'
var OFD_ORIGIN = 'http://anyloader.com'
var API_BASEURL = `${OFD_ORIGIN}/api/v1`

export const useRedgifs = () => {
	const {useExtension, removeKeys, getFileNameFromUrl, extFetch} = useHelpers()
	async function getAuthToken () {
		let {result: store} = await useExtension('chrome.storage.local.get', ['redGifsToken'])
		if (store?.redGifsToken) return store.redGifsToken
		let {result: storage} = await useExtension('getLocalStorage', ['https://www.redgifs.com'])
		let sessionInfo = JSON.parse(storage.session_data || 'null')
		await useExtension('chrome.storage.local.set', [{ redGifsToken: sessionInfo?.token }])
		return sessionInfo?.token
    }
    async function api (method, path, options) {
		let token = await getAuthToken()
		if (options?.qs) {
			path += '?' + new URLSearchParams(removeKeys(options.qs, (key, value) => !value))
			delete options.qs
		}
		let {body} = await extFetch(`https://api.redgifs.com/v2${path}`, {
			method,
			headers: {
				"accept": "application/json, text/plain, */*",
				"authorization": `Bearer ${token}`,
			},
			...options
		})
        return body
	}
	function parseMedia (item) {
		return {
			website: 'redgifs.com',
			type: 'video',
			duration: item.duration,
			url: item.urls.hd,
			fileName: getFileNameFromUrl(item.urls.hd),
			previewUrl: item.urls.sd,
			thumbnail: item.urls.thumbnail,
		}
	}
	async function getUserMedia (username, page = 1) {
		let data = await api('GET', `/users/${username}/search?order=new&count=40&page=${page}`)
		return {
			items: data.gifs.map(parseMedia),
			cursor: page + 1
		}
	}
	async function getPostMedia (postId) {
		let data = await api('GET', `/gifs/${postId}`)
		return [parseMedia(data.gif)]
	}
	return {
		getUserMedia,
		getPostMedia
	}
}