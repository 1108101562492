<template>
	<div v-loading="loading" id="paypal-button-container"></div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js"
let config = useConfig()
let store = {}

export default {
	data: () => ({
		loading: true
	}),
    beforeCreate() {
        store = useStore()
    },
	async mounted () {
		let paypal
		try {
			paypal = await loadScript({ 
				clientId: config.paypal.clientId,
				components: 'buttons',
				vault: true,
				intent: 'subscription',
				disableFunding: 'paylater,card'
			})
		} catch (error) {
			console.error("failed to load the PayPal JS SDK script", error)
		}

		if (paypal) {
			try {
				await paypal.Buttons({
					style: {
						shape: 'rect',
						color: 'blue',
						layout: 'vertical',
						label: 'subscribe'
					},
					createSubscription: function(data, actions) {
						return actions.subscription.create({
							plan_id: config.paypal.planId
						});
					},
					async onApprove (data, actions) {
						let {result: user, success} = await useApi("/user/me/subscription", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-jwt-token": localStorage.getItem('jwt')
							},
							body: JSON.stringify({
								status: 'Active',
								method: 'Paypal',
                                ownerId: config.paypal.id,
								id: data.subscriptionID
							}),
						})
						if (success) {
							store.user = user
                            gtag('event', 'conversion', {
                                'send_to': 'AW-11441119488/xeqNCNOm7P8YEIDCxs8q'
                            })
							ElNotification({
								type: 'success',
								title: 'Success',
								message: 'Subscription was successful!'
							})
						} else {
							ElNotification({
								type: 'error',
								title: 'Failed',
								messsage: 'Unknown error occured! Contact support if the amount was deducted from your account.'
							})
						}
					},
					
				}).render("#paypal-button-container")
			} catch (error) {
				console.error("failed to render the PayPal Buttons", error)
			}
		}
		this.loading = false
	}
}
</script>

<style>

</style>