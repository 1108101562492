<template>
<el-form ref="downloadForm" :model="form" size="large" label-position="top">
    <el-form-item label="Download from">
        <el-radio-group v-model="form.source">
            <el-radio-button label="post">Post</el-radio-button>
            <el-radio-button label="user">User</el-radio-button>
            <el-radio-button label="messages">Messages</el-radio-button>
            <el-radio-button label="all">All Posts</el-radio-button>
        </el-radio-group>
    </el-form-item>
	<div class="">
		<div v-if="form.source == 'post'"> 
			<el-form-item label="🔗 Link to post" prop="url" required>
				<el-input v-model="form.url" placeholder="Link to post" clearable></el-input>
			</el-form-item>
		</div>
		<div v-if="form.source == 'user'"> 
			<el-form-item prop="url" label="🔗 Link to profile" required>
				<el-input v-model="form.url" placeholder="Link to profile" clearable></el-input>
			</el-form-item>
		</div>
		<div v-if="form.source == 'messages'"> 
			<el-form-item prop="url" label="🔗 Link to chat" required>
				<el-input v-model="form.url" placeholder="Link to chat" clearable></el-input>
			</el-form-item>
		</div>
	</div>
	<div v-if="!extensionInfo.version">
		<div class="mb-4">
			<div class="mb-4">
				Please download and install the extension, 
                then come back and refresh this page to start downloading.
			</div>
			<div class="flex gap-4 items-center">
				<a :href="`anyloader-${config.extensionVersion}.zip`">
					<el-button :icon="ElIconChromeFilled">Download extension</el-button>
				</a>
				<el-popover title="How to install the extension" width="500" trigger="click">
					<template #reference>
						<div class="flex gap-2">
							<ElIconInfoFilled width="15" class="text-blue-500"></ElIconInfoFilled>
							<a class="text-blue-400 cursor-pointer">How to install</a>
						</div>
					</template>
					<ExtensionInfo></ExtensionInfo>
				</el-popover>
			</div>
		</div>
	</div>
	<div v-else-if="extensionInfo.version < config.extensionVersion">
		<div v-if="form.method == 'extension'" class="mb-4">
			<div class="mb-4">
				<el-alert title="Extension update required" type="warning" effect="light" show-icon :closable="false">
					<p> You have an outdated version of extension installed (v{{extensionInfo.version}})  </p>
                    <p> Please uninstall it, and download and install the latest version (v{{ config.extensionVersion }}) of the extension,  </p>
					<p> then come back and refresh this page to start downloading. </p>
				</el-alert>
			</div>
			<div class="flex gap-5 items-center">
				<a :href="`anyloader-${config.extensionVersion}.zip`">
					<el-button :icon="ElIconDownload">Download latest version</el-button>
				</a>
				<el-popover title="How to install the extension" width="500" trigger="click">
					<template #reference>
						<div class="flex gap-2">
							<ElIconInfoFilled width="15" class="text-blue-500"></ElIconInfoFilled>
							<a class="text-blue-400 cursor-pointer">How to install</a>
						</div>
					</template>
					<ExtensionInfo></ExtensionInfo>
				</el-popover>
			</div>
		</div>
	</div>
	<div v-else-if="!me.loading && !me?.id" class="mb-4">
		<el-alert title="Onlyfans account not detected" type="warning" show-icon closable>
			Login to your onlyfans account to download content you are subscribed to.
		</el-alert>
	</div>
	<div>
		<el-button class="mb-2" type="primary" @click="$emit('download', form)" :icon="ElIconSearch">Find Media</el-button>
	</div>
</el-form>
</template>

<script>
const config = useConfig()
export default {
	props: {
		value: Object,
		extensionInfo: Object,
		me: Object,
		loading: Boolean
	},
	mounted () {
        if (this.$route.query.url) {
            this.form.postUrl = this.$route.query.url
            this.$nextTick(() => {
                this.$emit('download', this.form)
            })
        }
	},
	data: () => ({
		config,
		form: {
			method: 'extension',
			source: 'post',
			url: ''
		},
	}),
    methods: {
    }
}
</script>

<style>

</style>