import _ from 'lodash'
const ORIGIN = 'https://onlyfans.com'
const APPTOKEN = '33d57ade8c02dbc5a333db99ff9ae26a'
var OFD_ORIGIN = 'http://anyloader.com'
var API_BASEURL = `${OFD_ORIGIN}/api/v1`

export const useOnlyfans = () => {
	const {useExtension, removeKeys, extFetch, getCookies, getFileNameFromUrl} = useHelpers()

    async function api (method, path, options) {
		let cookies = await getCookies({url: ORIGIN, name: 'fp'})
		let xbc = cookies[0]?.value
		cookies = await getCookies({url: ORIGIN, name: 'auth_id'})
		let userId = cookies[0]?.value
		if (options?.qs) {
			path += '?' + new URLSearchParams(removeKeys(options.qs, (key, value) => !value))
			delete options.qs
		}
		let securityHeaders = await getSecurityHeaders(path, userId)
		let {body} = await extFetch(`${ORIGIN}/api2/v2${path}`, {
			method, 
			headers: {
				"accept": "application/json, text/plain, */*",
				"app-token": APPTOKEN,
				'x-bc': xbc,
				'user-id': userId,
				...securityHeaders,
				referer: ORIGIN
			},
			...options
		})
        return body
	}
	async function getSecurityHeaders (path, userId = 0) {
		let cookies = await getCookies({url: ORIGIN})
		let {body} = await extFetch(API_BASEURL + '/onlyfans/getSecurityHeaders', {
			method: 'post',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({path, userId, cookies})
		})
		return body.result
	}
    
	async function getMe () {
		let {result: data} = await useExtension('client.api', ['get', `/users/me`])
		return data.id ? _.pick(data, ['id', 'email', 'name']) : {}
	}
	
	async function getUser (url) {
		let username = getUsernameFromUrl(url)
		let {result: data} = await useExtension('client.api', ['get', `/users/${username}`])
		return data
	}
	function parseMediaInfo (media) {
		let url = media.files?.full?.url
		return {
			id: media.id,
			url,
			drm: !!media.files.drm,
			thumbnail: media.files.squarePreview?.url || media.files.preview?.url,
			type: media.type == 'photo' ? 'image' : media.type,
			canView: media.canView,
			fileName: url && getFileNameFromUrl(url),
			previewUrl: media.videoSources?.['720'] || url
		}
	}
	async function getMediaFromUser ({url, cursor, sortBy = 'tips_sum', sortType = 'desc'}) {
		let user = await this.getUser(url)
        let qs = {
            limit: 10,
            order: `${sortBy}_${sortType}`,
            skip_users: 'all',
            format: 'infinite',
            pinned: 0,
            counters: 0,
            beforePublishTime: cursor
        }
		let {result: data} = await useExtension('client.api', ['get', `/users/${user.id}/posts`, {qs}])
		let items = []
		for (let post of data.list) {
			if (!post.media) continue
			for (let media of post.media) {
				if (!media.canView) continue
				items.push({
					user: user,
					source: 'user',
					website: 'onlyfans',
					...parseMediaInfo(media)
				})
			}
		}
		return {
			items,
			cursor: sortType == 'desc' ? data.tailMarker : data.headMarker
		}
	}
	
	async function getMediaFromPost (url) {
		let postId = getPostIdFromUrl(url)
		let {result: post} = await useExtension('client.api', ['get', `/posts/${postId}?skip_users=all`])
		let items = []
		if (!post.media) return []
		for (let media of post.media) {
			if (!media.canView) continue
			items.push({
				post: post,
				source: 'post',
				website: 'onlyfans',
				...parseMediaInfo(media)
			})
		}
		return items
	}
	
	async function getMediaFromMessages ({url, cursor}) {
		let chatId = getChatIdFromUrl(url)
        let qs = {
            limit: 10,
            order: 'desc',
            skip_users: 'all',
            id: cursor
        }
		let {result: data} = await useExtension('client.api', ['get', `/chats/${chatId}/messages`, {qs}])
		let items = []
		for (let chat of data.list) {
			if (!chat.media) continue
			for (let media of chat.media) {
				if (!media.canView) continue
				items.push({
					chat: chat,
					source: 'chat',
					website: 'onlyfans',
					...parseMediaInfo(media)
				})
			}
		}
		return {
			items,
			cursor: data.list?.length ? data.list[data.list.length - 1].id : ''
		}
	}

	async function getMediaFromFollowing ({offset}) {
		let {result} = await useExtension('client.api', ['get', `/lists/following/posts`, {qs: {offset, limit: 10}}])
		let items = []
		for (let post of result.list) {
			if (!post.media) continue
			for (let media of post.media) {
				if (!media.canView) continue
				items.push({
					post,
					source: 'all',
					website: 'onlyfans',
					...parseMediaInfo(media)
				})
			}
		}
		return {
			items
		}
	}
	
	function getPostIdFromUrl (url) {
		let urlObject = new URL(url)
		let pathSegments = urlObject.pathname.split('/')
		return pathSegments[1]
	}
	
	function getChatIdFromUrl (url) {
		if (!url.match('https://onlyfans.com/my/chats/chat/')) {
			throw new Error('Invalid URL')
		}
		return new URL(url).pathname.split('/')[4]
	}
	
	function getUsernameFromUrl (url) {
		if (!url.match('https://onlyfans.com/')) {
			throw new Error('Invalid URL')
		}
		return new URL(url).pathname.split('/')[1]
	}

	return {
		getMe,
		getUser,
		getPostIdFromUrl,
		getChatIdFromUrl,
		getUsernameFromUrl,
		getMediaFromUser,
		getMediaFromPost,
		getMediaFromFollowing,
		getMediaFromMessages,
	}
}
