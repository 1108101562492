<template>
<div>
    <div>
        <div class="mt-2 flex gap-2 text-red-500" v-if="job.error">
            <ElIconCircleCloseFilled :width="16"></ElIconCircleCloseFilled> Operation failed: {{ job.error }}
        </div>
        <div class="mt-2" v-else>
            <el-tag class="mr-1" type="warning" effect="dark" v-if="job.type == 'drm'">DRM</el-tag> Video {{state.phase}} in progress
            <span v-if="state.phase == 'download'">, {{ Math.round(state.progress.done/1e6) }}/{{ Math.round(state.progress.total/1e6) }} MB</span>
        </div>
        <div class="mt-2 flex gap-2">
            <el-progress class="flex-grow" :text-inside="true" :stroke-width="18" :percentage="Math.round(state.progress.progress * 100)"></el-progress>
            <el-button style="zoom: 1.5; height: auto; padding: 0" class="flex-shrink" type="text" :icon="ElIconCircleCloseFilled" @click="cancelJob(job)"></el-button>
        </div>
    </div>
</div>
</template>

<script>
const {cancelJob} = useHelpers()

var messageHandler

export default {
    props: ['job'],
    methods: {
        cancelJob
    },
    computed: {
        state () {
            return this.job.state
        }
    }
}
</script>