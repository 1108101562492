<template>
    <div v-if="jobs().length > 0" class="mb-4">
        <div class="flex justify-between items-center mb-6">
            <h4 class="my-0 flex items-center text-gray-500 gap-2">
                <ElIconDownload :width="16"></ElIconDownload>
                Downloads
            </h4>
            <el-button @click="cancelAll()" type="text">Cancel All</el-button>
        </div>
        <div v-for="item in drmJobs">
            <DownloadProgress :job="item"/>
        </div>
        <div v-for="item in downloadJobs">
            <DownloadProgress :job="item"/>
        </div>
        <div class="my-2 flex items-center gap-2" v-if="waitingJobs.length > 0">
           <ElIconInfoFilled :width="16"></ElIconInfoFilled> {{waitingJobs.length}} more downloads are waiting in queue
        </div>
    </div>
</template>

<script>
const {cancelJob, useExtension} = useHelpers()

export default {
    inject: ['jobs'],
    computed: {
        waitingJobs () {
            return this.jobs().filter(job => job.status == 'waiting')
        },
        drmJobs () {
            return this.jobs().filter(job => job.type == 'drm' && job.status != 'waiting')
        },
        downloadJobs () {
            return this.jobs().filter(job => job.type == 'download' && job.status != 'waiting')
        },
    },
    methods: {
        async cancelAll () {
            this.jobs().forEach(job => {
                cancelJob(job)
            })
            await useExtension('taskManager.cancelAll', [], 'sandbox')
            this.$forceUpdate()
        }
    }
}
</script>