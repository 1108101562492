import _ from 'lodash'
var OFD_ORIGIN = 'http://anyloader.com'
var API_BASEURL = `${OFD_ORIGIN}/api/v1`

export const useFansly = () => {
	const {useExtension, removeKeys, getCookies, extFetch} = useHelpers()

    async function api (method, path, options) {
		let {result: fanslyToken} = await useExtension('fansly.getAuthToken', [], 'bg')
		if (options?.qs) {
			path += '?' + new URLSearchParams(removeKeys(options.qs, (key, value) => !value))
			delete options.qs
		}
		let {body} = await extFetch(`https://apiv3.fansly.com/api/v1${path}`, {
			method,
			headers: {
				"accept": "application/json, text/plain, */*",
				"authorization": fanslyToken,
			},
			...options
		})
        return body
	}

	async function getMe () {
		let {result: data} = await useExtension('client.api', ['get', `/users/me`])
		return data.id ? _.pick(data, ['id', 'email', 'name']) : {}
	}

	async function getUser (url) {
		let username = getUsernameFromUrl(url)
		let {result: data} = await useExtension('client.api', ['get', `/users/${username}`])
		return data
	}
	

	async function getCreatorId (username) {
		const data = await api('GET', `/account?usernames=${username}`)
		return data.response[0].id
	}
	
	function parseMediaInfo({access, media}) {
		if (media.locations && media.locations.length > 0) {
			const highestQualityUrl = media.locations.reduce((prev, current) => 
				(prev.quality > current.quality) ? prev : current
			).location;
			let [type, extension] = media.mimetype.split('/')
			let metadata = JSON.parse(media.metadata)
			return {
				type,
				duration: metadata?.duration,
				canView: access,
				thumbnail: type == 'image' ? highestQualityUrl : '',
				url: highestQualityUrl,
				previewUrl: highestQualityUrl,
				createdAt: media.createdAt,
				fileName: `${media.id}.${extension}`
			}
		}
		return null;
	}
	
	async function getSinglePostDownloadUrls(postId) {
		const data = await api('GET', `/post?ids=${postId}&ngsw-bypass=true`)
		const mediaObjects = data.response.accountMedia
		return mediaObjects.map(parseMediaInfo).filter(Boolean)
	}
	
	async function getCollectionsDownloadUrls() {
		const collectionsData = await api('GET', '/account/media/orders/?limit=9999&offset=0&ngsw-bypass=true')
		const accountMediaIds = collectionsData.response.accountMediaOrders.map(order => order.accountMediaId).join(',')
		
		const mediaData = await api('GET', `/account/media?ids=${accountMediaIds}`)
		return mediaData.response.map(parseMediaInfo).filter(Boolean)
	}
	
	async function getMessagesDownloadUrls({chatId, cursor}) {
		// const groupsData = await api('GET', '/group')
		// const group = groupsData.response.groups.find(group => group.users.some(user => user.userId === creatorId))
		// if (!group) return []
		const downloadUrls = []
		const params = cursor ? `groupId=${chatId}&before=${cursor}&limit=25&ngsw-bypass=true` : `groupId=${chatId}&limit=25&ngsw-bypass=true`
		const messagesData = await api('GET', `/message?${params}`)			
		const mediaUrls = messagesData.response.accountMedia.map(parseMediaInfo).filter(Boolean)
		downloadUrls.push(...mediaUrls)
		if (messagesData.response.messages.length > 0) {
			cursor = messagesData.response.messages[messagesData.response.messages.length - 1].id
		}
		return {
			items: downloadUrls,
			cursor
		}
	}
	
	async function getCreatorDownloadUrls({username, creatorId, cursor}) {
		const downloadUrls = []
		if (!creatorId) creatorId = await getCreatorId(username)
		const data = await api('GET', `/timelinenew/${creatorId}?before=${cursor}&after=0&wallId=&contentSearch=&ngsw-bypass=true`)
		const mediaUrls = data.response.accountMedia.map(parseMediaInfo).filter(Boolean)
		downloadUrls.push(...mediaUrls);
		if (data.response.posts.length > 0)
			cursor = data.response.posts[data.response.posts.length - 1].id
		return {
			items: downloadUrls,
			cursor
		}
	}
	
	return {
		getMe,
		getUser,
		getCreatorDownloadUrls,
		getMessagesDownloadUrls,
		getCollectionsDownloadUrls,
		getSinglePostDownloadUrls,
	}
}
