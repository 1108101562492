<template>
	<div>
		<el-timeline>
			<el-timeline-item> Download and unzip the extension file</el-timeline-item>
			<el-timeline-item> Go to Chrome menu → Extensions → Manage extensions </el-timeline-item>
			<el-timeline-item> Enable 'Developer mode' on top right corner </el-timeline-item>
			<el-timeline-item> Drag and drop the unzipped extension folder to the page. Alternatively, you can click on 'Load unpacked extension' button and select the unzipped extension folder to install</el-timeline-item>
			<el-timeline-item> Make sure the extension appears in the extensions page, then come back and refresh this page </el-timeline-item>
		</el-timeline>
	</div>
</template>