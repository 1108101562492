<template>
<div class="flex-grow flex flex-col items-center " :class="{ 'bg-gray-100 justify-center': !media.canView }">
	<div>
		<div v-if="media.type == 'video'" class="mb-4">		
			<video preload="metadata" style="width: 100%"  controls v-bind="media.thumbnail ? ({poster: media.thumbnail}) : ({})">
				<source :src="media.previewUrl">
				Your browser does not support the video tag.
			</video>
			<DownloadProgress v-if="job" :job="job"></DownloadProgress>
		</div>
		<el-tag v-else-if="media.type == 'audio'" class="mb-4" size="large">
			<ElIconHeadset width="18" class="inline-block mr-2"></ElIconHeadset>
			Audio file: {{media.duration}} seconds
		</el-tag>
		<div v-else-if="media.type == 'image'" class="mb-4">
			<el-image :src="media.thumbnail" fit="fill">
				<template #placeholder>
					<div v-loading="true">
						<ElIconLoading></ElIconLoading>
					</div>
				</template>
				<template #error>
					<ElIconPictureRounded></ElIconPictureRounded>
				</template>
			</el-image>
		</div>

		<div v-else class="mb-4">
			Unknown media type: {{media.type}}
		</div>
		<a v-if="!job" class="mr-4 cursor-pointer text-xl flex items-center gap-2">
			<div v-if="downloading" class="flex items-center gap-2"> 
				<ElIconCheck width="18" class="inline-block mr-1"></ElIconCheck>
				Download requested 
			</div>
			<div v-else-if="!media.drm" @click="download(media, source)" class="flex items-center gap-2">
				<ElIconDownload width="18"></ElIconDownload>
				Download
			</div>
			<el-tag size="medium" type="error" v-else>
				<div class="flex items-center gap-2">
					<ElIconClose width="24" class="inline-block mr-1"></ElIconClose>
					<div> Downloading not supported </div>
				</div>
			</el-tag>
		</a>
	</div>
</div>
</template>

<script>
const {download} = useHelpers()
export default {
	props: {
		job: Object,
		media: Object,
        source: String
	},
	data () {
		return {
			downloading: false
		}
	},
	methods: {
		download (...args) {
			this.downloading = true
			download(...args)
		}
	}
}
</script>

<style>

</style>