<template>
	<div id="g_button" v-loading="loading"></div>
</template>

<script>
let config = useConfig()
let store = {}

export default {
	props: {
		context: String,
		width: String,
		callback: Function
	},
    beforeCreate() {
        store = useStore()
    },
	data: () => ({
		loading: false
	}),
	async mounted () {
		await this.loadGSI()
		window.google?.accounts.id.initialize({
			context: this.context,
			client_id: config.google.signIn,
			scope: 'email profile',
			ux_mode: 'popup',
			itp_support: true,
			callback: async response => {
				this.loading = true
				let data = await useApi('/oauth/google/verify', {
					method: 'post',
					body: response
				})
				store.user = data.result.user
				localStorage.setItem('jwt', data.result.token)
				this.loading = false
			}
		})
		window.google?.accounts.id.renderButton(document.getElementById('g_button'), {
			type: 'standard',
			shape: 'rectangular',
			theme: 'outline',
			text: 'signin_with',
			size: 'large',
			logo_alignment: 'center',
			width: this.width
		 })
	},
	methods: {
		loadGSI () {
			return new Promise((resolve, reject) => {
				const scriptTag = document.createElement("script")
				scriptTag.src = "https://accounts.google.com/gsi/client"
				scriptTag.onload = resolve
				scriptTag.onerror = reject
				document.head.appendChild(scriptTag)
			})
		},
	}
}
</script>

<style>

</style>